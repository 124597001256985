/* .map-container  {
    width: '50%';
    height: '80vh';
    position: 'absolute';
      top:'0';
      bottom: '0';
      left: '0';
      right: '0';
      margin: 'auto'
}; */

/* h1 {
    position:absolute;
    z-index:20;
    top: 1rem;
    left: 1rem;
    margin:0;
    padding: 5%;    
} */

.autocomplete-dropdown-container{
    position: "absolute";
    z-index: 1000;
 }

.title-style {
    font-size: 80 !important;   
}

.sidebar-tab {
    font-size: 50 !important;
    font-family: 'Arial';    
}

.site-info {
    font-size: medium;
    font-weight: 600;
    color:#002D4E;
}

.site-desc-info {
    font-size: small;
    font-weight: 400;
}

.model-url {
    font-size: small;
    font-weight: 400;   
}

.model-url-list {
    font-size: small;
    font-weight: 400;
    text-decoration: none;
    color:black;
}

.model-unavail-url {
    font-size: small;
    font-weight: 400;
    color:lightgray;
}

.bg-color {
    background-color: rgb(197, 216, 233);
}

.gm-style .gm-style-iw-d {
    overflow: hidden !important; 
    padding:0%;
}

.gm-style .gm-style-iw-c {
   padding: 5px 10px 5px 5px !important;
   min-width: 150px !important;
   min-height: 28px !important;
   /* min-width: 0px !important; */
   position: absolute;
   /* box-sizing: border-box; */
   overflow: hidden !important;;
   top: 0;
   left: 0;   
   transform: translate(-50%,-100%);
   /* background-color: #16ab40; */
   border-radius: 10px;
   box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
}

/* .gm-style-iw-t::after{
    background: #16ab40 !important;
} */

.theme-button {
    position: absolute;
    right:10px;
    z-index:10;    
}

.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}


.pac-container {
    z-index: 99999 !important;
}